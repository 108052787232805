@import "~providiobase/utilities/globalmixins";

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

@mixin roundedSlickArrow {
    .slick-arrow {
        background: var(--color-white);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        font-family: "FontAwesome";
        font-size: 1.5rem;
        color: var(--colo-primary);
        box-shadow: #ccc 0px 1px 4px;
    
        &:hover {
            background: var(--color-white);
        }
    
        &.slick-prev {
            left: 2%;
    
            &::after {
                content: '\f053 ';
                margin-left: -4px;
            }
        }
    
        &.slick-next {
            right: 1%;
    
            &::after {
                content: '\f054 ';
                margin-right: -4px;
            }
        }
    }
    
}