@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../../utilities/globalmixins";

.einstein-pd-slick-carousel {
	.plp-pd-product-tile {
		padding: 0 10px;
		margin-bottom: 30px;
		display: flex;
		justify-content: center;

		.product-tile {
			.quickview  {
				display: none;
			}
		}
		@include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
	}
	.slick-list {
		padding: 0 25% 0 0;
		@include media-breakpoint-up(md) {
			padding: 0;
		}

	}
	.slick-track {
		display: flex;
		justify-content: center;
	}

	&.carousel-inner {
		overflow: initial;
	}

	// includes arrounded styled slick arrow
	@include roundedSlickArrow;
}
.slick-slide {
	height: auto;
	margin: 3px;
}
